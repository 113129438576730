<template>
  <div is="template">
    <template v-if="$slots.default">
      <CustomMarker v-bind="$props" :marker="o_position" @click.native="onClick">
        <slot />
      </CustomMarker>
      <gmapMarker
        v-if="draggable"
        v-once
        :zIndex="zIndex"
        :position="o_position"
        :opacity="0"
        @click="onClick"
        @drag="onDrag"
        @dragend="onDragend"
        draggable
      />
    </template>
    <gmapMarker
      v-else
      :zIndex="zIndex"
      :position="o_position"
      @click="onClick"
      @drag="onDrag"
      @dragend="onDragend"
      :draggable="draggable"
    />
    <gmap-info-window
      v-if="o_isInfowindowOpen"
      :options="{ pixelOffset: { width: 0, height: -40 } }"
      :position="o_position"
      @closeclick="closeInfowindow"
    >
      <slot name="infowindow" />
    </gmap-info-window>
  </div>
</template>

<script>
import CustomMarker from 'vue2-gmap-custom-marker';
export default {
  components: {
    CustomMarker
  },
  data() {
    return {
      o_isInfowindowOpen: this.toBoolean(this.autoOpenInfowindow),
      o_position: this.position
    };
  },
  methods: {
    decodeGLatLng({ lat, lng }) {
      if (lat && lng) {
        const latitude = typeof lat === 'function' ? lat() : lat;
        const longitude = typeof lng === 'function' ? lng() : lng;
        return { lat: latitude, lng: longitude };
      }
    },
    toBoolean(item) {
      if (item === undefined) return false;
      if (item === '') return true;
      return !!item;
    },
    onDragend(G) {
      const position = this.decodeGLatLng(G.latLng);
      this.$emit('dragend', position);
      this.o_position = position;
    },
    onDrag(G) {
      const position = this.decodeGLatLng(G.latLng);
      this.$emit('drag', position);
      this.o_position = position;
    },
    onClick() {
      this.$emit('click', { position: this.o_position });
      this.toggleInfowindow();
    },
    closeInfowindow() {
      this.o_isInfowindowOpen = false;
    },
    openInfowindow() {
      if (this.$slots.infowindow) {
        this.o_isInfowindowOpen = true;
      }
    },
    toggleInfowindow() {
      if (this.$slots.infowindow) {
        this.o_isInfowindowOpen = !this.o_isInfowindowOpen;
      }
    }
  },
  watch: {
    o_isInfowindowOpen(value) {
      this.$emit('update:isInfowindowOpen', value);
    },
    autoOpenInfowindow(value) {
      this.o_isInfowindowOpen = this.toBoolean(value);
    },
    position(value) {
      this.o_position = value;
    }
  },
  props: {
    draggable: { type: Boolean, default: false },
    autoOpenInfowindow: { type: Boolean, default: false },
    position: { type: Object, default: () => {} },
    zIndex: { type: Number, default: 1 }
  }
};
</script>

<style lang="sass" scoped></style>
